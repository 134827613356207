import APIService from '@/services/APIService.js';

export default {
  upload(postData, onUploadProgress) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 60 * 60 * 1000,
      onUploadProgress
    };

    return APIService.apiCall().post('arquivos/upload', postData, config);
  }
};
