var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm._files.length > 0)?_c('v-row',{staticClass:"mx-auto mb-5",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.uploadAllFiles()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-upload-outline ")]),_vm._v(" Enviar tudo ")],1)],1),_c('v-col',{attrs:{"cols":"auto","sm":"4"}},[_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){_vm._files = []}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Cancelar tudo ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"id":"upload-queue","headers":[
        { text: 'Nome', value: 'name' },
        { text: 'Tamanho', value: 'size' },
        { text: 'Ações', value: 'actions' }
      ],"items":_vm._files,"hide-default-footer":"","hide-default-header":"","disable-filtering":"","disable-sort":"","disable-pagination":"","mobile-breakpoint":740},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate-column font-weight-medium",staticStyle:{"max-width":"25vw"},attrs:{"id":"upload-queue-name"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.size",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm._f("bytesToSize")(item.size)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.uploadFile(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-upload-outline")]),_vm._v(" Enviar ")],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"grey","small":""},on:{"click":function($event){_vm._files.splice(
              _vm._files.findIndex(function (file) { return file.id === item.id; }),
              1
            )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar ")],1)]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }