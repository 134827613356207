import APIService from '@/services/APIService.js';

export default {
  upload(postData, onUploadProgress) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 60 * 60 * 1000,
      onUploadProgress
    };
    return APIService.apiCall().post('arquivos-xml/upload', postData, config);
  },
  baixarArquivo(id) {
    return APIService.apiCall().get(`arquivos-xml/baixar-arquivo/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  }
};
