<template>
  <div>
    <v-select
      v-model="simulationTypeId"
      :label="label"
      :items="simulationsType"
      item-value="id"
      item-text="nome"
      :outlined="type === 'outlined'"
      class="mb-0 pb-0"
      no-data-text="Nenhum tipo de simulação disponível"
      :loading="loading"
      @change="handleSelectedSimulationTypeId"
    />
    <v-alert
      text
      color="info"
      v-if="simulationType && simulationType.descricao"
    >
      {{ simulationType.descricao }}
    </v-alert>
  </div>
</template>

<script>
import SimulacoesIndqualService from '@/services/SimulacoesIndqualService';
export default {
  name: 'SelectIndqualSimulationType',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    label: {
      type: String,
      default: 'Simulação INDQUAL'
    }
  },
  data() {
    return {
      simulationTypeId: null,
      simulationsType: [],
      loading: false
    };
  },
  mounted() {
    this.getIndqualSimulationsType();
  },
  computed: {
    simulationType() {
      return this.simulationsType.find(
        (simulation) => simulation.id === this.simulationTypeId
      );
    }
  },
  methods: {
    getIndqualSimulationsType() {
      this.loading = true;
      SimulacoesIndqualService.getTiposSimulacoesAtivas()
        .then((response) => (this.simulationsType = response.data.data))
        .finally(() => (this.loading = false));
    },
    handleSelectedSimulationTypeId(simulationTypeId) {
      this.$emit('selected:simulationType', this.simulationType);
      this.$emit('selected:simulationTypeId', simulationTypeId);
    }
  }
};
</script>
