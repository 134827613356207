<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Executar Simulação INDQUAL"
      subtitle="Escolha o tipo de simulação que deseja realizar"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <simulacoes-indqual-wizard-tabs
        ref="simulacaoParametros"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import SimulacoesIndqualService from '@/services/SimulacoesIndqualService';
import SimulacoesIndqualWizardTabs from '@/components/geracao-bdgd/indqual/simulacoes/SimulacoesIndqualWizardTabs';
import MaterialWizard from '@/components/base/MaterialWizard';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    SimulacoesIndqualWizardTabs,
    MaterialWizard
  },
  data: () => ({
    tabs: ['Informações básicas', 'Parâmetros', 'Confirmação'],
    loading: false
  }),
  methods: {
    save() {
      const data = this.$refs.simulacaoParametros.exportData();
      this.loading = true;
      SimulacoesIndqualService.save(data)
        .then(() => {
          this.$toast.success('Simulação INDQUAL salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['VALIDACOES_INDQUAL']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a simulação do INDQUAL.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
