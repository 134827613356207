<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemsFirstTab"
        :titleItem.sync="titleItem"
        :loading.sync="loadingFirstTab"
        @stepReleased="$emit('step-observer', { step: 0, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione a simulação e preencha os parâmetros obrigatórios
      </div>
      <second-tab-content
        @selected:simulationTypeId="handleSimulationTypeIdSelected"
        @update:parameters="handleParametersUpdated"
        @stepReleased="
          $emit('step-observer', { step: 1, complete: $event }),
            $emit('step-observer', { step: 2, complete: $event })
        "
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <third-tab-content />
    </v-tab-item>
  </v-container>
</template>

<script>
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import FirstTabContent from '@/components/geracao-bdgd/indqual/simulacoes/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/geracao-bdgd/indqual/simulacoes/tabs/SecondTabContent.vue';
import ThirdTabContent from '@/components/geracao-bdgd/indqual/simulacoes/tabs/ThirdTabContent.vue';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent,
    SecondTabContent,
    ThirdTabContent
  },
  created() {
    this.fillFirstTab();
  },
  data() {
    return {
      simulacao: {},

      //First Tab
      loadingFirstTab: false,
      bdgdVersionId: null,
      itemsTableFirstTab: [],
      titleItem: null,
      selectedItemsFirstTab: [],

      //Second Tab
      simulationTypeId: null,
      parameters: {}
    };
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    async fillFirstTab() {
      this.loadingFirstTab = true;
      await ExportacoesBdgdsService.getDatesAndRegistersImportedEntities()
        .then(({ data }) => {
          this.itemsTableFirstTab = data;
          let selectedBdgdVersion = this.itemsTableFirstTab.filter(
            (item) =>
              item.bdgd_versao_id === this.userSelectedCompanyBdgdVersion.id
          );
          this.selectedItemsFirstTab = selectedBdgdVersion;
        })
        .finally(() => {
          this.loadingFirstTab = false;
        });
    },
    handleSimulationTypeIdSelected(simulationTypeId) {
      this.simulationTypeId = simulationTypeId;
    },
    handleParametersUpdated(parameters) {
      this.parameters = parameters;
    },
    exportData() {
      return {
        nome: this.titleItem,
        parametros: this.parameters,
        tipo_simulacao_id: this.simulationTypeId,
        bdgd_versao_id: this.selectedItemsFirstTab[0].bdgd_versao_id
      };
    }
  },
  watch: {
    selectedItemsFirstTab(arrNewVal) {
      this.bdgdVersionId = arrNewVal.length
        ? arrNewVal[0].bdgd_versao_id
        : null;
    }
  }
};
</script>
