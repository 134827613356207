<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <upload-files
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        />
      </v-col>
    </v-row>
    <v-row v-if="lastUploadedFile">
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadFiles from '@/components/general/UploadFiles/Index.vue';
export default {
  name: 'FormSimulacaoRetificacaoIndqual',
  components: {
    UploadFiles
  },
  data: () => ({
    allowedFiles: ['csv'],
    uploadedFiles: []
  }),
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  methods: {
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    }
  },
  watch: {
    uploadedFiles() {
      this.$emit('update:parameters', {
        arquivo_retira_ucs_id: this.lastUploadedFile.id
      });
    }
  }
};
</script>
