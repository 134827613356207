import APIService from './APIService';

export default {
  getAll() {
    return APIService.apiCall().get('/simulacoes-indqual/get-all');
  },
  getTiposSimulacoesAtivas() {
    return APIService.apiCall().get('/simulacoes-indqual/get-tipos-simulacoes-ativas');
  },
  executar(id) {
    return APIService.apiCall().get(`/simulacoes-indqual/executar/${id}`);
  },
  deletar(id) {
    return APIService.apiCall().get(`/simulacoes-indqual/excluir/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/simulacoes-indqual/save',
      JSON.stringify(postData)
    );
  },
  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/simulacoes-indqual/download-arquivo-zip-logs/${id}`,
      { responseType: 'blob', ...config }
    );
  },
};