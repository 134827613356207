<template>
  <div>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <select-indqual-simulation-type
          type="outlined"
          @selected:simulationType="handleSimulationTypeSelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="simulationType"
    >
      <v-col
        class="pb-0"
        cols="12"
      >
        <div class="text-center display-1 font-weight-normal">
          Envie um arquivo CSV com a lista de UCs a serem retiradas do INDQUAL
        </div>
        <component
          v-bind:is="getFormSimulationParameters(simulationType)"
          @update:parameters="handleParametersUpdated"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectIndqualSimulationType from '@/components/general/SelectIndqualSimulationType.vue';
import FormSimulacaoRetificacaoIndqual from '@/components/geracao-bdgd/indqual/simulacoes/forms/FormSimulacaoRetificacaoIndqual.vue';
export default {
  components: {
    SelectIndqualSimulationType,
    FormSimulacaoRetificacaoIndqual
  },
  data: () => ({
    simulationType: null,
    simulationTypeId: null
  }),
  methods: {
    getFormSimulationParameters(simulationType) {
      return `form-simulacao-${simulationType.slug}`;
    },
    handleSimulationTypeSelected(simulationType) {
      this.simulationType = simulationType;
      this.simulationTypeId = simulationType ? simulationType.id : null;
    },
    handleParametersUpdated(parameters) {
      this.$emit('update:parameters', parameters);
    }
  },
  watch: {
    simulationTypeId(simulationTypeId) {
      this.$emit('selected:simulationType', this.simulationType);
      this.$emit('selected:simulationTypeId', simulationTypeId);
      this.$emit('stepReleased', simulationTypeId !== null);
    }
  }
};
</script>
